<template>
  <cp-general-modal
    ref="createModal"
    :title="title"
    title-icon="ion ion-ios-person-add"
    :ok-disabled="disabled"
    @shown="setInitialState"
    @onOk="saveChanges"
  >
    <div class="d-flex justify-content-between">
      <div class="w-100 mr-3">
        <cp-input
          v-model="inputs.name"
          name="name"
          :label="$t('addEditCustodianModal.input.name')"
          validate="required"
        />
      </div>

      <div class="w-100">
        <cp-input
          v-model="inputs.friendlyName"
          name="friendlyName"
          :label="$t('addEditCustodianModal.input.friendlyName')"
          validate="required"
        />
      </div>
    </div>
    <cp-input
      v-model="inputs.email"
      name="email"
      :label="$t('addEditCustodianModal.input.email')"
      validate="required|email"
    />
    <cp-input
      v-model="inputs.wallet"
      name="wallet"
      :label="$t('addEditCustodianModal.input.wallet')"
    />
    <div>
      <h6 class="mt-1 mb-2">
        {{ $t('addEditCustodianModal.input.logo') }}
      </h6>
      <cp-file-uploader
        v-model="inputs.logo"
        name="logo"
        extension=".jpeg,.jpg,.png,.gif"
        :default-url="inputs.logo"
        :max-file-size="maxImgSize"
        @onChange="onLogoUploaded"
        @fileLoading="fileLoading"
      />
    </div>
  </cp-general-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import CpGeneralModal from '@/components/common/modals-components/general-modal';
import CpInput from '@/components/common/standalone-components/inputs/cp-input';
import CpFileUploader from '~/components/common/standalone-components/cp-file-uploader';

export default {
  name: 'AddEditCustodianModal',
  components: {
    CpGeneralModal,
    CpInput,
    CpFileUploader,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    viewMode: {
      type: String,
      required: true,
    },
    prePopulatedInput: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      inputs: {
        name: '',
        email: '',
        wallet: '',
        friendlyName: '',
        logo: '',
      },
      maxImgSize: 5,
      initialInputs: {},
      currentView: '',
      disabled: false,
    };
  },
  computed: {
    ...mapGetters('configuration', ['getGeneral']),
  },
  watch: {
    prePopulatedInput(newVal) {
      this.inputs = { ...newVal };
      this.initialInputs = { ...newVal };
    },
    viewMode(newVal) {
      if (newVal) this.currentView = newVal;
    },
    inputs: {
      deep: true,
      handler(newInputs) {
        this.disabled = (_.isEqual(newInputs, this.initialInputs) && this.currentView === 'update');
      },
    },
  },
  methods: {
    show() {
      this.$refs.createModal.show();
    },
    hide() {
      this.$refs.createModal.hide();
    },
    onLogoUploaded({ fileKey }) {
      this.inputs.logo = fileKey || null;
      this.disabled = false;
    },
    saveChanges(body) {
      this.disabled = true;
      this.$emit('onSaveCustodian', { ...body, id: this.inputs.id, logo: this.inputs.logo }, this.currentView);
    },
    setInitialState() {
      this.disabled = (_.isEqual(this.inputs, this.initialInputs) && this.currentView === 'update');
    },
    fileLoading() {
      this.disabled = true;
    },
  },
};
</script>
